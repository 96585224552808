import { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import mainBG from '../assets/img/img_bg_main_2.jpg';
import characterMale from '../assets/img/game/character_m.png';
import characterFemale from '../assets/img/game/character_f.png';
import imgGoal from '../assets/img/game/img_pre_game_01.png';
import LogoWrap from '../components/Logo';
import iconGuide1 from '../assets/img/game/icon_guide_01.svg';
import iconGuide2 from '../assets/img/game/icon_guide_02.svg';


function ScreenPreGame({ onEnterGame, userInfo }) {
    return (
        <>
            <div className='page page-pregame' id="preGameScreen">
                <div className='container'>
                    <div className='wrapper'>
                        <img className='main-bg' src={mainBG} alt='Ho Chi Minh City International Marathon - Techcombank' />

                        <div className='el-wrap top'>
                            <LogoWrap />
                        </div>
                        <div className='el-wrap text-center bottom'>
                            <div className='pregame-wrap'>
                                <div className='img-goal'>
                                    <img src={imgGoal} />
                                </div>

                                <div className='character'>
                                    <div className='mascot'>
                                        <img src={userInfo.player && userInfo.player === "0" ? characterMale : characterFemale} />
                                    </div>
                                    <div className='name'>
                                        {userInfo.name ? userInfo.name : ''}
                                    </div>
                                </div>

                                <div className='guide'>
                                    <div>
                                        <img width={32} src={iconGuide1} />
                                        <p>
                                            Chạm vào màn hình<br /> để Nhảy
                                        </p>
                                    </div>
                                    <div>
                                        <img width={46} src={iconGuide2} />
                                        <p>
                                            Bạn có 30 giây<br /> để hoàn thành vòng chạy
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='btn btn-yellow' onClick={onEnterGame}>
                                <span>
                                    XUẤT PHÁT
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 30 30" fill="none">
                                        <g filter="url(#filter0_ddd_90_8499)">
                                            <path d="M23.2205 15.9956L23.2219 15.9942L17.2313 10.0011L17.2299 10.0024L11.2301 4L5.23955 9.99316L11.2394 15.9956L5.23071 22.0069L11.2213 28L17.2299 21.9887L17.2402 21.9989L23.2307 16.0058L23.2205 15.9956Z" fill="white" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_ddd_90_8499" x="0.230713" y="0" width="28" height="30" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="1" />
                                                <feGaussianBlur stdDeviation="2.5" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0.532764 0 0 0 0 0.0516202 0 0 0 0 0.0516202 0 0 0 0.2 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_90_8499" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="3" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0.482764 0 0 0 0 0.0467756 0 0 0 0 0.0467756 0 0 0 0.12 0" />
                                                <feBlend mode="normal" in2="effect1_dropShadow_90_8499" result="effect2_dropShadow_90_8499" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="2" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0.357764 0 0 0 0 0.100254 0 0 0 0 0.100254 0 0 0 0.14 0" />
                                                <feBlend mode="normal" in2="effect2_dropShadow_90_8499" result="effect3_dropShadow_90_8499" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_90_8499" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                </span>
                            </div>

                        </div>



                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
}

export default ScreenPreGame;
