import logoMarathon from '../assets/img/logo_marathon.svg';
import logo30Years from '../assets/img/logo_30years.svg';
import { Link } from 'react-router-dom';

const LogoWrap = () => {
    return (
        <div className='logo-wrap'>
            <Link to='/' className='logo-marathon'>
                <img src={logoMarathon} />
            </Link>
            <img src={logo30Years} className='logo-30years'/>
        </div>
    )
}

export default LogoWrap;