
import useElementFullHeight from '../hooks/useElementFullHeight';
import Footer from '../components/Footer';

const RankingPage = () => {

    //container height
    const elementHeight = useElementFullHeight('.element-to-exclude');

    return (
        <div className='ranking-page general-page' id="rankingpage">
            <div className='container' style={{ minHeight: `${elementHeight}px` }}>
                <h1>Ranking Page</h1>
            </div>

            <div className='container element-to-exclude'>
                <Footer />
            </div>
        </div>
    )
}

export default RankingPage;