import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import mainBG from "../assets/img/img_bg_main.jpg";
import iconClose from "../assets/img/icon_close.svg";
import iconNext from "../assets/img/icon_arrow_next.svg";
import iconPrev from "../assets/img/icon_arrow_prev.svg";
import iconTrophy from "../assets/img/icon_trophy.svg";
import buttonLogo from "../assets/img/button_logo.svg";
import { Link, useNavigate } from "react-router-dom";

import PopupComponent from "../components/PopupComponent";
import LogoWrap from "../components/Logo";

const HomePage = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState(``);
  const [onCloseState, setOnCloseState] = useState(false);
  const [onCloseRankingState, setOnCloseRankingState] = useState(false);
  const [onCloseRuleState, setOnCloseRuleState] = useState(false);
  const [usersRanking, setUsersRanking] = useState([]);
  const [currentUser, setCurrentUser] = useState(0);

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  var requestOptions = {
    method: "GET",
    headers: headers,
  };

  const handleToken = () => {
    // const getTokenStorage = localStorage.getItem("Rtoken");
    // if (getTokenStorage) {
    //   // console.log(getTokenStorage);
    //   navigate("/play");
    // } else {
    //   // console.log('No token');
    //   setMessage(`Bạn chưa đăng nhập!`);
    //   setOnCloseState(!onCloseState);
    // }
    // console.log("handleToken");

    //Assume has token
    navigate("/play");
  };

  const handleOnClose = () => {
    setOnCloseState(!onCloseState);
  };

  const handleOnCloseRanking = () => {
    setOnCloseRankingState(!onCloseRankingState);
  };

  const handleOnCloseRule = () => {
    setOnCloseRuleState(!onCloseRuleState);
  };

  const handleLoadRanking = async () => {
    // try {
    //     fetch(`${process.env.REACT_APP_API_URI}/api/gettop`, requestOptions)
    //     .then(res => res.json())
    //     .then(data => {
    //         if(data.data) setUsersRanking(data.data);
    //         return data;
    //     });
    // } catch (error) {
    //     console.log(error);
    // }

    //Dummy data - start
    const data = [
      {
        name: "Phong Nguyễn",
        score: 9989,
      },
      {
        name: "Peter N.",
        score: 4045,
      },
      {
        name: "Peter N 2.",
        score: 3839,
      },
      {
        name: "Peter N 3.",
        score: 2122,
      },
      {
        name: "Peter N 3A.",
        score: 1250,
      },
      {
        name: "Peter N. 5",
        score: 998,
      },
      {
        name: "Peter N 6.",
        score: 889,
      },
      {
        name: "Peter N 7.",
        score: 789,
      },
      {
        name: "Peter N 8.",
        score: 787,
      },
    ];

    setUsersRanking(data);

    //Dummy data - end
  };

  const handleCurrentUser = async () => {
    // try {
    //   fetch(`${process.env.REACT_APP_API_URI}/api/getuser`, requestOptions)
    //     .then((res) => res.json())
    //     .then((data) => {
    //       setCurrentUser(data.data.users);
    //     });
    // } catch (e) {
    //   console.log(e);
    // }

    //Dummy data - start
    setCurrentUser(199);
  };

  useEffect(() => {
    if (onCloseRankingState === true) {
      handleLoadRanking();
    }
  }, [onCloseRankingState]);

  useEffect(() => {
    handleCurrentUser();
  }, []);

  return (
    <div className="page home" id="homePage">
      <div className="container">
        <div className="wrapper">
          <img
            className="main-bg"
            src={mainBG}
            alt="Ho Chi Minh City International Marathon - Techcombank"
          />

          <div className="el-wrap top">
            <LogoWrap />
            <h1>
              Bước Chạy <br /> Vượt Trội
            </h1>
          </div>

          <div className="el-wrap bottom">
            <div
              className="btn btn-blue"
              onClick={() => setOnCloseRankingState(true)}
            >
              <span>
                <img src={iconPrev} />
                <img
                  src={iconNext}
                  style={{ marginLeft: `-6px`, marginRight: `5px` }}
                />
                TOP 30 Vượt Trội
              </span>
            </div>

            <div
              className="btn btn-blue"
              onClick={() => setOnCloseRuleState(true)}
            >
              <span>Thể lệ tham gia</span>
            </div>

            <Link className="btn btn-red" to="/">
              <span>
                <img
                  src={buttonLogo}
                  alt="Ho Chi Minh City International Marathon - Techcombank"
                />
              </span>
            </Link>

            <div className="gradient-box">
              <div className="users-in-game">
                Đã có <strong>{currentUser}</strong> người tham gia
              </div>
            </div>

            <div className="btn btn-yellow mt-0" onClick={handleToken}>
              <span>Chơi ngay!</span>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <PopupComponent onClose={onCloseState} type={`warning`}>
        <div className="box" onClick={handleOnClose}>
          <div className="content" onClick={(e) => e.stopPropagation()}>
            <p>{message}</p>
            <Link className="btn btn-blue" to="/login">
              <span>Đăng nhập</span>
            </Link>
            <div className="close-btn" onClick={handleOnClose}>
              <img src={iconClose} />
            </div>
          </div>
        </div>
      </PopupComponent>

      <PopupComponent onClose={onCloseRankingState} type={`ranking`}>
        <div className="box" onClick={handleOnCloseRanking}>
          <div className="content" onClick={(e) => e.stopPropagation()}>
            <div className="btn btn-blue btn-title">
              <span>
                <img src={iconPrev} />
                <img src={iconNext} />
                <strong>TOP 30 Vượt Trội</strong>
              </span>
            </div>

            <div className="users-list">
              <ul className="scrollable-content">
                {usersRanking.map((user, index) => {
                  return (
                    <li key={index}>
                      {index === 0 ? (
                        <img className="user-trophy" src={iconTrophy} />
                      ) : (
                        <span className="user-order">
                          <strong>{index + 1}</strong>
                        </span>
                      )}
                      <span className="user-name">{user.name}</span>
                      <span className="user-point">{user.score}</span>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="close-btn" onClick={handleOnCloseRanking}>
              <img src={iconClose} />
            </div>
          </div>
        </div>
      </PopupComponent>

      <PopupComponent onClose={onCloseRuleState} type={`rules`}>
        <div className="box" onClick={handleOnCloseRule}>
          <div className="content" onClick={(e) => e.stopPropagation()}>
            <div className="btn btn-blue btn-title">
              <span>
                <strong>Thể lệ Tham gia</strong>
              </span>
            </div>
            <div className="scrollable-content rule-content">
              <p>
                <strong>Bài 1: Giới thiệu về trò chơi</strong>
              </p>
              <p>
                "Techcombank Run" là một trò chơi trực tuyến được tổ chức bởi
                Techcombank, nhằm tạo sân chơi giải trí cho người chơi và cung
                cấp cơ hội để các thành viên có thể giành được những giải thưởng
                hấp dẫn.
              </p>

              <p>
                <strong>Bài 2: Điều kiện tham gia</strong>
              </p>
              <ul>
                <li>
                  Người chơi phải đăng ký tài khoản thành viên trên trang web
                  của "Techcombank Run".
                </li>
                <li>Người chơi phải từ 18 tuổi trở lên.</li>
                <li>
                  Người chơi phải có quốc tịch Việt Nam hoặc cư trú hợp pháp tại
                  Việt Nam.
                </li>
                <li>
                  Nhân viên của Techcombank và các công ty liên kết không được
                  phép tham gia trò chơi.
                </li>
                <li></li>
                <li></li>
              </ul>
              <p>
                <strong>Bài 3: Cách thức tham gia</strong>
              </p>
              <p>
                Người chơi đăng nhập vào tài khoản của mình trên trang web
                "Techcombank Run". Thực hiện các nhiệm vụ và hoàn thành các cấp
                độ trong trò chơi để tích lũy điểm. Điểm số của người chơi sẽ
                được xếp hạng trên bảng xếp hạng tổng. Người chơi có thể theo
                dõi điểm số và xếp hạng của mình thông qua trang cá nhân.
              </p>
              <p>
                <strong>Bài 4: Giải thưởng</strong>
              </p>
              <ul>
                <li>
                  Giải thưởng sẽ được trao cho những người chơi có điểm số cao
                  nhất trong thời gian diễn ra trò chơi.
                </li>
                <li>
                  Các giải thưởng bao gồm tiền mặt, thẻ quà tặng, sản phẩm công
                  nghệ và nhiều phần quà hấp dẫn khác.
                </li>
                <li>Danh sách người chiến thắng sẽ </li>
              </ul>
            </div>
            <div className="close-btn" onClick={handleOnCloseRule}>
              <img src={iconClose} />
            </div>
          </div>
        </div>
      </PopupComponent>
    </div>
  );
};

export default HomePage;
