import { useEffect, useState } from "react";

const PopupComponent = ({ children, onClose, type }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(onClose);
    }, [onClose]);
    
    return (
        <div className={`popup ${type}
                    ${show && `active`}`}>
            <div className="container">
                {children}
            </div>
        </div>
    )
}

export default PopupComponent;