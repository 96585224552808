import React, { useRef, useEffect } from 'react';
import * as PIXI from 'pixi.js';
import { sound } from '@pixi/sound';

import srcImgBgLogo from '../assets/img/game/img_bg_logo.png';
import srcImgBgFar from '../assets/img/game/img_bg_far.png';
import srcImgBgNear from '../assets/img/game/img_bg_near.png';
import srcImgGround from '../assets/img/game/ground.jpg';
import srcImgRunnerMale from '../assets/img/game/character_m.png';
import srcImgRunnerFemale from '../assets/img/game/character_f.png';
import srcImgObjGold from '../assets/img/game/img_object_gold.png';
import srcImgObjSilver from '../assets/img/game/img_object_silver.png';
import srcImgObjBronze from '../assets/img/game/img_object_bronze.png';
import srcImgObjX2 from '../assets/img/game/img_object_x2.png';
import srcImgObjBoots from '../assets/img/game/img_object_boost.png';
import srcImgKv from '../assets/img/img_kv.png';
import srcImgBuilding1 from '../assets/img/game/img_building_01.png';
import srcImgBuilding2 from '../assets/img/game/img_building_02.png';
import srcImgScoreFrame from '../assets/img/game/img_score_frame.svg';
import srcImgStartLine from '../assets/img/game/img_start_line.png';

import srcSoundCountdownGo from '../assets/sound/countdown-go.mp3';
import srcSoundFinish from '../assets/sound/finish.mp3';
import srcSoundJump from '../assets/sound/jump.mp3';
import srcSoundSpecialItem from '../assets/sound/special_item.mp3';
import srcSoundItem from '../assets/sound/coin.mp3';
import srcSoundBg from '../assets/sound/soundtrack.mp3';

sound.add('soundCountdownGo', srcSoundCountdownGo);
sound.add('soundFinish', srcSoundFinish);
sound.add('soundJump', srcSoundJump);
sound.add('soundSpecialItem', srcSoundSpecialItem);
sound.add('soundItem', srcSoundItem);
sound.add('soundBg', srcSoundBg);

function ScreenOnGame({ onEndGame, userInfo }) {
    const gameCanvas = useRef(null);

    let score = 0;
    let initTimeLeft = 30;
    let timeLeft = initTimeLeft;
    let gameSpeed = 1;
    const totalRewardObj = 30;
    let mainStep = 5;

    //On game
    let isPlaying = false;
    let isJumping = false;
    let isLanding = false;

    // Declare intervals outside the gameSetup function
    // let renderObjectInterval;
    let countDownInGameInterval;
    let countDownToStartInterval;

    // Function reload resource
    const preloadResource = async () => {
        const data = {};

        data['imgBgLogo'] = await PIXI.Assets.load(srcImgBgLogo);
        data['imgBgFar'] = await PIXI.Assets.load(srcImgBgFar);
        data['imgBgNear'] = await PIXI.Assets.load(srcImgBgNear);
        data['imgGround'] = await PIXI.Assets.load(srcImgGround);
        if (userInfo.player === "0") {
            data['imgRunner'] = await PIXI.Assets.load(srcImgRunnerMale);
        } else {
            data['imgRunner'] = await PIXI.Assets.load(srcImgRunnerFemale);
        }
        data['imgObjGold'] = await PIXI.Assets.load(srcImgObjGold);
        data['imgObjSilver'] = await PIXI.Assets.load(srcImgObjSilver);
        data['imgObjBronze'] = await PIXI.Assets.load(srcImgObjBronze);
        data['imgObjX2'] = await PIXI.Assets.load(srcImgObjX2);
        data['imgObjBoots'] = await PIXI.Assets.load(srcImgObjBoots);
        data['imgKv'] = await PIXI.Assets.load(srcImgKv);
        data['imgBuilding1'] = await PIXI.Assets.load(srcImgBuilding1);
        data['imgBuilding2'] = await PIXI.Assets.load(srcImgBuilding2);
        data['imgScoreFrame'] = await PIXI.Assets.load(srcImgScoreFrame);
        data['imgStartLine'] = await PIXI.Assets.load(srcImgStartLine);

        return data;
    }

    useEffect(() => {
        const updateGameWrapperHeight = () => {
            if (gameCanvas.current) {
                const windowHeight = window.innerHeight;
                gameCanvas.current.style.height = `${windowHeight}px`;
                // Get the size of the container
                // setCanvasWidth(gameCanvas.current.clientWidth);
                // setCanvasHeight(gameCanvas.current.clientHeight)
            }
        };

        // Gọi hàm cập nhật chiều cao khi kích thước cửa sổ thay đổi
        window.addEventListener('resize', updateGameWrapperHeight);

        // Gọi hàm cập nhật chiều cao khi component được mount
        updateGameWrapperHeight();

        // Khởi tạo game CANVAS
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isMobile = PIXI.utils.isMobile.any;
        const pixiApp = new PIXI.Application({
            width: gameCanvas.current.clientWidth,
            height: gameCanvas.current.clientHeight,
            resolution: (isIOS ? (window.devicePixelRatio / 2 < 1 ? 1 : window.devicePixelRatio / 2) : window.devicePixelRatio) || 1,
            antialias: true,
            backgroundColor: '#FFD6B0',
            forceCanvas: false,
            transparent: false,
            powerPreference: 'high-performance'
        });

        // Create a new PIXI.TextStyle object
        const textStyle = new PIXI.TextStyle({
            fontFamily: 'Montserrat Alternates',
            fontWeight: '900',
            fontSize: 24,
            fill: 0xd10f10,
            lineHeight: 36,
            align: 'center',
            antialias: true,
            wordWrap: true,
            wordWrapWidth: gameCanvas.current.clientWidth - 80,
        });

        // Create a PIXI.Text object with the specified text and style
        const loadingText = new PIXI.Text(`${isMobile ? 'Đang tải trò chơi...' : 'Vui lòng chơi game bằng trình duyệt Web của smartphone bạn nhé.'}`, textStyle);
        loadingText.anchor.set(0.5, 0.5);

        loadingText.x = gameCanvas.current.clientWidth / 2;
        loadingText.y = gameCanvas.current.clientHeight / 2;
        // Add the text to your PIXI stage or container
        pixiApp.stage.addChild(loadingText);

        // Append PIXI app to the HTML element
        gameCanvas.current.appendChild(pixiApp.view);

        //Load assets
        preloadResource().then(textureList => {
            if (!isMobile) {
                return;
            }
            //Xoá loading text
            pixiApp.stage.removeChild(loadingText);

            gameSetup(pixiApp, textureList);
        });

        return () => {
            //console.log("Destroy game and intervals");
            pixiApp.destroy(true);
            sound.stop('soundCountdownGo');
            sound.stop('soundFinish');
            sound.stop('soundJump');
            sound.stop('soundSpecialItem');
            sound.stop('soundItem');
            sound.stop('soundBg');
            // Clear the intervals when the component is unmounted
            clearInterval(countDownInGameInterval);
            clearInterval(countDownToStartInterval);
        };

    }, [])

    const gameSetup = (app, textureList) => {
        //===== Background: LOGO
        const imgBgLogoHeight = gameCanvas.current.clientHeight * 0.5;
        const imgBgLogoTexture = textureList['imgBgLogo'];
        const imgBgLogo = new PIXI.TilingSprite(imgBgLogoTexture, gameCanvas.current.clientWidth, imgBgLogoHeight);
        // Tính tỷ lệ giữa chiều cao của TilingSprite và chiều cao của texture
        const imgBgLogoScale = imgBgLogoHeight / imgBgLogoTexture.height;
        // Đặt tỷ lệ cho texture sử dụng tileScale
        imgBgLogo.tileScale.y = imgBgLogoScale
        imgBgLogo.tileScale.x = imgBgLogoScale
        imgBgLogo.y = gameCanvas.current.clientHeight * 0.75 - imgBgLogoHeight;


        //===== Background: FAR
        const imgBgFarHeight = gameCanvas.current.clientHeight * 0.3;
        const imgBgFarTexture = textureList['imgBgFar'];
        const imgBgFar = new PIXI.TilingSprite(imgBgFarTexture, gameCanvas.current.clientWidth, imgBgFarHeight);
        const imgBgFarScale = imgBgFarHeight / imgBgFarTexture.height;
        imgBgFar.tileScale.y = imgBgFarScale
        imgBgFar.tileScale.x = imgBgFarScale
        imgBgFar.y = gameCanvas.current.clientHeight * 0.6 - imgBgFarHeight / 5 * 3.5;


        //===== Background: near
        const imgBgNearHeight = gameCanvas.current.clientHeight * 0.15;
        const imgBgNearTexture = textureList['imgBgNear'];
        const imgBgNear = new PIXI.TilingSprite(imgBgNearTexture, gameCanvas.current.clientWidth, imgBgNearHeight);
        const imgBgNearScale = imgBgNearHeight / imgBgNearTexture.height;
        imgBgNear.tileScale.y = imgBgNearScale
        imgBgNear.tileScale.x = imgBgNearScale
        imgBgNear.y = gameCanvas.current.clientHeight * 0.75 - imgBgNearHeight;


        //===== Ground
        const imgGroundHeight = gameCanvas.current.clientHeight * 0.25;
        const imgGroundTexture = textureList['imgGround'];
        const imgGround = new PIXI.TilingSprite(imgGroundTexture, gameCanvas.current.clientWidth, imgGroundHeight);
        const imgGroundScale = imgGroundHeight / imgGroundTexture.height;
        imgGround.tileScale.y = imgGroundScale
        imgGround.tileScale.x = imgGroundScale
        imgGround.y = gameCanvas.current.clientHeight - imgGroundHeight;

        //===== Start Line in Ground
        const imgStartLineTexture = textureList['imgStartLine'];
        const imgStartLine = new PIXI.Sprite(imgStartLineTexture, gameCanvas.current.clientWidth, imgGroundHeight / 2);
        const imgStartLineScale = (imgGroundHeight / 2.25) / imgStartLineTexture.height;
        imgStartLine.scale.y = imgStartLineScale
        imgStartLine.scale.x = imgStartLineScale
        imgStartLine.y = gameCanvas.current.clientHeight - imgGroundHeight * 0.94;
        imgStartLine.x = gameCanvas.current.clientWidth * 0.3;


        //===== Building
        const generalBuildingHeight = gameCanvas.current.clientHeight * 0.5;
        const imgBuilding1Texture = textureList['imgBuilding1'];
        const imgBuilding2Texture = textureList['imgBuilding2'];
        const imgBuilding3Texture = textureList['imgBuilding1'];
        const imgBuilding4Texture = textureList['imgBuilding2'];
        const imgBuilding5Texture = textureList['imgBuilding1'];
        const imgBuilding6Texture = textureList['imgBuilding2'];

        const imgBuilding1 = new PIXI.Sprite(imgBuilding1Texture);
        const imgBuilding2 = new PIXI.Sprite(imgBuilding2Texture);
        const imgBuilding3 = new PIXI.Sprite(imgBuilding3Texture);
        const imgBuilding4 = new PIXI.Sprite(imgBuilding4Texture);
        const imgBuilding5 = new PIXI.Sprite(imgBuilding5Texture);
        const imgBuilding6 = new PIXI.Sprite(imgBuilding6Texture);

        // Tính tỷ lệ giữa chiều cao của TilingSprite và chiều cao của texture
        const imgBuilding1Scale = generalBuildingHeight / imgBuilding1Texture.height;
        const imgBuilding2Scale = generalBuildingHeight / imgBuilding2Texture.height;
        const imgBuilding3Scale = generalBuildingHeight / imgBuilding3Texture.height;
        const imgBuilding4Scale = generalBuildingHeight / imgBuilding4Texture.height;
        const imgBuilding5Scale = generalBuildingHeight / imgBuilding5Texture.height;
        const imgBuilding6Scale = generalBuildingHeight / imgBuilding6Texture.height;

        // Đặt tỷ lệ cho texture sử dụng tileScale
        imgBuilding1.scale.y = imgBuilding1Scale;
        imgBuilding1.scale.x = imgBuilding1Scale;
        imgBuilding2.scale.y = imgBuilding2Scale;
        imgBuilding2.scale.x = imgBuilding2Scale;
        imgBuilding3.scale.y = imgBuilding3Scale;
        imgBuilding3.scale.x = imgBuilding3Scale;
        imgBuilding4.scale.y = imgBuilding4Scale;
        imgBuilding4.scale.x = imgBuilding4Scale;
        imgBuilding5.scale.y = imgBuilding5Scale;
        imgBuilding5.scale.x = imgBuilding5Scale;
        imgBuilding6.scale.y = imgBuilding6Scale;
        imgBuilding6.scale.x = imgBuilding6Scale;

        //roadLength = time x fps * step
        const roadLength = initTimeLeft * 60 * mainStep //number is frame per second;

        imgBuilding1.y = gameCanvas.current.clientHeight * 0.7650 - generalBuildingHeight;
        imgBuilding1.x = gameCanvas.current.clientWidth * 0.4;
        imgBuilding2.y = gameCanvas.current.clientHeight * 0.7650 - generalBuildingHeight;
        imgBuilding2.x = roadLength / 6 * 1;
        imgBuilding3.y = gameCanvas.current.clientHeight * 0.7650 - generalBuildingHeight;
        imgBuilding3.x = roadLength / 6 * 2;
        imgBuilding4.y = gameCanvas.current.clientHeight * 0.7650 - generalBuildingHeight;
        imgBuilding4.x = roadLength / 6 * 3;
        imgBuilding5.y = gameCanvas.current.clientHeight * 0.7650 - generalBuildingHeight;
        imgBuilding5.x = roadLength / 6 * 4;
        imgBuilding6.y = gameCanvas.current.clientHeight * 0.7650 - generalBuildingHeight;
        imgBuilding6.x = roadLength / 6 * 5;



        // Create a Sprite for the runner
        const imgRunnerTexture = textureList['imgRunner'];
        const imgRunner = new PIXI.Sprite(imgRunnerTexture);
        const imgRunnerScale = (gameCanvas.current.clientHeight * 0.18) / imgRunnerTexture.height;
        imgRunner.scale.y = imgRunnerScale;
        imgRunner.scale.x = imgRunnerScale;
        imgRunner.x = gameCanvas.current.clientWidth * 0.1; // Đặt vị trí của runner ở 10% chiều rộng
        const initRunnerY = gameCanvas.current.clientHeight - imgGroundHeight / 4 * 2.75 - imgRunner.height;
        imgRunner.y = initRunnerY



        //===== Create a Sprite using the loaded texture
        const imageKvTexture = textureList['imgKv'];
        const imageKvSprite = new PIXI.Sprite(imageKvTexture);
        const imgKvScale = (gameCanvas.current.clientWidth - 46) / imageKvTexture.width;

        imageKvSprite.scale.x = imgKvScale;
        imageKvSprite.scale.y = imgKvScale;
        imageKvSprite.anchor.set(0, 0.5);

        imageKvSprite.x = gameCanvas.current.clientWidth / 2 - imageKvSprite.width / 2;
        imageKvSprite.y = gameCanvas.current.clientHeight - imageKvSprite.height;


        //===== SCORE 
        const imgScoreFrameHeight = gameCanvas.current.clientHeight * 0.16;
        const imgScoreFrameTexture = textureList['imgScoreFrame'];
        const imgScoreFrame = new PIXI.Sprite(imgScoreFrameTexture);
        const imgScoreFrameScale = imgScoreFrameHeight / imgScoreFrameTexture.height;

        imgScoreFrame.scale.x = imgScoreFrameScale;
        imgScoreFrame.scale.y = imgScoreFrameScale;

        imgScoreFrame.x = gameCanvas.current.clientWidth / 2 - imgScoreFrame.width / 2;
        imgScoreFrame.y = gameCanvas.current.clientHeight * 0.02;

        const scoreContainer = new PIXI.Container();

        // Create a PIXI.Graphics object
        const graphics = new PIXI.Graphics();

        // Set the fill color for the background
        graphics.beginFill(0xFFAE02);

        // Draw a rectangle for the background
        graphics.drawRect(0, 0, gameCanvas.current.clientWidth, gameCanvas.current.clientHeight * 0.2);

        // End the fill
        graphics.endFill();

        // Set the line style for the border
        graphics.lineStyle(5, 0xFFFFFF); // 5 is the thickness, 0xFFFFFF is the white color

        // Draw a line at the bottom for the border
        graphics.moveTo(0, gameCanvas.current.clientHeight * 0.2);
        graphics.lineTo(gameCanvas.current.clientWidth, gameCanvas.current.clientHeight * 0.2);


        // Score text
        const scoreText = new PIXI.Text('', {
            fontFamily: 'Montserrat Alternates',
            fontWeight: '900',
            fontSize: ((gameCanvas.current.clientWidth * 0.13) > 50) ? 50 : gameCanvas.current.clientWidth * 0.13,
            fill: 0xffffff,
            antialias: true
        });

        scoreText.anchor.set(0.5, 0.5); // Đặt anchor point ở giữa trên cùng của text
        scoreText.position.set(imgScoreFrame.x + imgScoreFrame.width * 0.66, imgScoreFrame.y + imgScoreFrame.height / 2); // Đặt vị trí ở giữa trên cùng của màn hình

        setTimeout(() => {
            //Trường hợp ko load kịp font
            scoreText.text = `${score}`;
        }, 100)

        //===== COUNT DOWN TEXT
        const countDownText = new PIXI.Text(`${timeLeft}`, {
            fontSize: ((gameCanvas.current.clientWidth * 0.05) > 20) ? 20 : gameCanvas.current.clientWidth * 0.05,
            fontWeight: '700',
            fill: 0xffffff,
            antialias: true
        });

        countDownText.anchor.set(0.5, 0.5); // Đặt anchor point ở giữa trên cùng của text
        countDownText.position.set(imgScoreFrame.x + imgScoreFrame.width * 0.27678571, imgScoreFrame.y + imgScoreFrame.height / 2); // Đặt vị trí ở giữa trên cùng của màn hình

        // Add the graphics object to the container
        scoreContainer.addChild(graphics);
        scoreContainer.addChild(imgScoreFrame);
        scoreContainer.addChild(scoreText);
        scoreContainer.addChild(countDownText);

        //===== COUNTDOWN CIRCLE LINE
        // //Tạo đối tượng đồ họa Graphics để vẽ đường tròn
        // const circle = new PIXI.Graphics();
        // circle.lineStyle(3, 0xFFFFFF); // Độ dày border là 5px, màu trắng
        // circle.drawCircle(0, 0, gameCanvas.current.clientWidth * 0.045);
        // circle.endFill();
        // circle.x = imgScoreFrame.x + imgScoreFrame.width * 0.275;
        // circle.y = imgScoreFrame.y + imgScoreFrame.height / 2;
        // scoreContainer.addChild(circle);

        // // Thiết lập thời gian đếm ngược
        // const framesToDisappear = 60 * initTimeLeft; // Số frame để đường tròn biến mất
        // let currentFrame = 0;

        // // Hàm cập nhật đường tròn
        // function updateCountdownCircle() {
        //     if (currentFrame < framesToDisappear) {
        //         const percentRemaining = 1 - currentFrame / framesToDisappear;

        //         circle.clear();

        //         const radius = gameCanvas.current.clientWidth * 0.045;

        //         // Góc bắt đầu từ đỉnh (trên cùng của đường tròn)
        //         const startAngle = -Math.PI / 2;

        //         // Góc kết thúc theo chiều kim đồng hồ
        //         const endAngle = -Math.PI / 2 + 2 * Math.PI * percentRemaining;


        //         const anticlockwise = false;

        //         circle.lineStyle(3, 0xFFFFFF); // Độ dày border là 5px, màu trắng
        //         circle.arc(0, 0, radius, startAngle, endAngle, anticlockwise);

        //         currentFrame++;
        //     } else {
        //         app.ticker.stop();
        //     }
        // }


        //===== COUNTDOWN TO START
        const styleCountdown = new PIXI.TextStyle({
            fontFamily: 'Montserrat Alternates',
            fontWeight: '900',
            fontSize: ((gameCanvas.current.clientWidth * 0.13) > 52) ? 52 : gameCanvas.current.clientWidth * 0.13,
            fill: 0xffffff,
            stroke: 0xE50000, // Stroke color
            strokeThickness: ((gameCanvas.current.clientWidth * 0.03) > 15) ? 15 : gameCanvas.current.clientWidth * 0.03, // Stroke thickness
            antialias: true,
        });

        const countDownToStartText = new PIXI.Text('3', styleCountdown);
        countDownToStartText.position.set(gameCanvas.current.clientWidth * 0.1, gameCanvas.current.clientHeight / 2);
        countDownToStartText.rotation = (350 * Math.PI) / 180;
        //===== Objects
        const rewardObjects = [
            { type: 'objGold', appear: 0, texture: textureList['imgObjGold'] },
            { type: 'objSilver', appear: 0, texture: textureList['imgObjSilver'] },
            { type: 'objBronze', appear: 0, texture: textureList['imgObjBronze'] },
            { type: 'objBoots', appear: 0, texture: textureList['imgObjBoots'] },
            { type: 'objX2', appear: 0, texture: textureList['imgObjX2'] },
        ];

        const rewardObjSprites = [];

        //objects container
        const rewardObjContainer = new PIXI.Container();

        // Hàm để tạo đối tượng mới: các vật phẩm
        const createRandomObject = (posX) => {
            const radndomIndx = Math.floor(Math.random() * rewardObjects.length);
            const randomObject = rewardObjects[radndomIndx];

            rewardObjects[radndomIndx]["appear"]++;

            //limit 5 obj x2 or 5 boots
            if (
                rewardObjects[radndomIndx]["appear"] >= 5 &&
                ["objX2", "objBoots"].includes(rewardObjects[radndomIndx]["type"])
            ) {
                rewardObjects.splice(radndomIndx, 1);
            }

            const objTexture = randomObject.texture;
            const objSprite = new PIXI.Sprite(objTexture);

            // Thiết lập vị trí và kích thước cho sprite
            let objSpriteHeight = gameCanvas.current.clientHeight * 0.06;

            if (randomObject.type === 'objX2') {
                objSpriteHeight = gameCanvas.current.clientHeight * 0.07;
            }
            if (randomObject.type === 'objBoots') {
                objSpriteHeight = gameCanvas.current.clientHeight * 0.065;
            }

            objSprite.scale.x = objSpriteHeight / objTexture.height;
            objSprite.scale.y = objSpriteHeight / objTexture.height;
            objSprite.x = posX;

            objSprite.y = initRunnerY - (Math.random() < 0.5 ? 0 : imgRunner.height);
            objSprite.type = randomObject.type;
            objSprite.position.z = 100;


            // Thêm sprite vào mảng và stage
            rewardObjSprites.push(objSprite);
            rewardObjContainer.addChild(objSprite);
        };

        // Tạo đối tượng mới mỗi x giây
        for (var i = 0; i < totalRewardObj * 1.5; i++) {
            createRandomObject(gameCanvas.current.clientWidth * (i + 1) + 80);
        }


        // Hàm kiểm tra va chạm giữa runner và các đối tượng
        const checkCollision = () => {
            for (const obj of rewardObjSprites) {
                if (
                    imgRunner.x < obj.x + obj.width &&
                    imgRunner.x + imgRunner.width > obj.x &&
                    imgRunner.y < obj.y + obj.height &&
                    imgRunner.y + imgRunner.height > obj.y
                ) {
                    handleObjectCollision(obj);
                }
            }
        };

        // Hàm xử lý khi runner va chạm vào đối tượng
        const handleObjectCollision = (obj) => {
            // Xử lý tương ứng với loại đối tượng
            switch (obj.type) {
                case 'objGold':
                    // Tăng điểm
                    score = score + 10;
                    sound.play('soundItem');
                    break;
                case 'objSilver':
                    score = score + 5;
                    sound.play('soundItem');
                    break;
                case 'objBronze':
                    score = score + 2;
                    sound.play('soundItem');
                    break;
                case 'objBoots':
                    // Thêm thời gian
                    addTime(2);
                    sound.play('soundSpecialItem');
                    break;
                case 'objX2':
                    // Nhân đôi tổng điểm
                    score = score * 2;
                    sound.play('soundSpecialItem');
                    break;
                default:
                    break;
            }

            if (score > 9999) {
                scoreText.text = `9999+`;
                scoreText.style.fontSize = ((gameCanvas.current.clientWidth * 0.12) > 46) ? 46 : gameCanvas.current.clientWidth * 0.12

            } else {
                scoreText.text = `${score}`;
            }

            // Xóa đối tượng khỏi mảng và stage
            rewardObjSprites.splice(rewardObjSprites.indexOf(obj), 1);
            rewardObjContainer.removeChild(obj);
        };


        //Countdown
        let endBonus = false;
        countDownInGameInterval = setInterval(() => {
            if (isPlaying && timeLeft > 0) {
                timeLeft -= 1;
                countDownText.text = `${timeLeft}`;
            }

            if (isPlaying && timeLeft <= 0) {
                isPlaying = false;
                onEndGame(score);
                sound.play('soundFinish');
                sound.stop('soundBg');
                clearInterval(countDownInGameInterval);
            }

            if (timeLeft === 7 && !endBonus) {
                endBonus = true;
                createRandomObject(gameCanvas.current.clientWidth * 1.1);
                createRandomObject(gameCanvas.current.clientWidth * 1.5);
                createRandomObject(gameCanvas.current.clientWidth * 2);
                createRandomObject(gameCanvas.current.clientWidth * 2.5);
                createRandomObject(gameCanvas.current.clientWidth * 3);
                createRandomObject(gameCanvas.current.clientWidth * 3.2);
                createRandomObject(gameCanvas.current.clientWidth * 3.4);
                createRandomObject(gameCanvas.current.clientWidth * 3.6);
                createRandomObject(gameCanvas.current.clientWidth * 3.8);
                createRandomObject(gameCanvas.current.clientWidth * 4);
                createRandomObject(gameCanvas.current.clientWidth * 4.2);
                createRandomObject(gameCanvas.current.clientWidth * 4.3);
                createRandomObject(gameCanvas.current.clientWidth * 4.4);
                createRandomObject(gameCanvas.current.clientWidth * 4.5);
                createRandomObject(gameCanvas.current.clientWidth * 4.6);
                createRandomObject(gameCanvas.current.clientWidth * 4.7);
                createRandomObject(gameCanvas.current.clientWidth * 4.8);
                createRandomObject(gameCanvas.current.clientWidth * 4.9);
                createRandomObject(gameCanvas.current.clientWidth * 5);
                createRandomObject(gameCanvas.current.clientWidth * 5.1);
                createRandomObject(gameCanvas.current.clientWidth * 5.2);
            }



        }, 1000);


        // Hàm thêm thời gian
        const addTime = (seconds) => {
            timeLeft += seconds;
            countDownText.text = `${timeLeft}`;
        };

        //===== Check if app.stage exists before adding the sprite
        if (app.stage) {
            // Add the sprite to the stage
            app.stage.addChild(imgBgLogo);
            app.stage.addChild(imgBgFar);
            app.stage.addChild(imgBgNear);
            app.stage.addChild(imgGround);
            app.stage.addChild(imgStartLine);
            app.stage.addChild(imgBuilding1);
            app.stage.addChild(imgBuilding2);
            app.stage.addChild(imgBuilding3);
            app.stage.addChild(imgBuilding4);
            app.stage.addChild(imgBuilding5);
            app.stage.addChild(imgBuilding6);
            app.stage.addChild(imageKvSprite);
            app.stage.addChild(imgRunner);
            app.stage.addChild(scoreContainer);
            app.stage.addChild(rewardObjContainer);
            app.stage.addChild(countDownToStartText);

            // Thêm nút bắt đầu và đếm ngược thời gian
            const startGame = () => {
                isPlaying = true;
                score = 0;
                sound.play('soundBg');
            };


            const handleTouchStart = (e) => {
                //Jumping
                if (isPlaying && !isJumping && !isLanding) {
                    // console.log("cho nhảy")
                    isJumping = true;
                    sound.play('soundJump');
                }
            };

            //Event click
            const canvasElement = app.view;
            canvasElement.addEventListener('click', handleTouchStart);

            let countDownToStartNumber = 3;
            sound.play('soundCountdownGo');
            countDownToStartInterval = setInterval(() => {
                countDownToStartNumber--;
                // console.log("countDownToStartNumber", countDownToStartNumber);
                if (countDownToStartNumber >= 0) {
                    countDownToStartText.text = `${countDownToStartNumber !== 0 ? countDownToStartNumber : 'GO!!!'}`;
                }
                if (countDownToStartNumber <= 0) {
                    startGame();
                    setTimeout(() => {
                        countDownToStartText.text = '';
                    }, 500)

                    clearInterval(countDownToStartInterval);
                }
            }, 800)


            //TIKCER ANIMATION
            PIXI.Ticker.targetFPMS = 0.06; // 60 frames per second Adjust this value

            const jumpingThreshold = initRunnerY - gameCanvas.current.clientHeight * 0.3;

            app.ticker.add(function (delta) {

                // Now do the animation
                if (isPlaying) {
                    //collision detection
                    checkCollision();

                    rewardObjSprites.forEach((sprite) => {
                        if (sprite.x <= -sprite.width) {
                            // sprite.x = gameCanvas.current.clientWidth + (Math.random() * (gameCanvas.current.clientWidth * 0.2));
                            // sprite.y = initRunnerY - (Math.random() < 0.5 ? 0 : imgRunner.height);
                        } else {
                            sprite.x -= mainStep / gameSpeed;
                        }
                    });


                    if (isJumping) {
                        //nhảy lên
                        imgRunner.y -= 7 / gameSpeed;
                        //nếu vượt ngưỡng
                        if (imgRunner.y <= jumpingThreshold) {
                            isJumping = false;
                        }
                    } else if (imgRunner.y < initRunnerY) {
                        //rớt xuống
                        imgRunner.y += 8 / gameSpeed;
                    }

                    if (!isJumping && imgRunner.y < initRunnerY) {
                        isLanding = true;
                    }

                    // Xử lý isLanding khi runner ở mức dưới cùng
                    if (imgRunner.y >= initRunnerY) {
                        isJumping = false;
                        isLanding = false;
                    }

                    imgBgLogo.tilePosition.x -= (mainStep / 50) / gameSpeed * delta;
                    imgBgFar.tilePosition.x -= (mainStep / 17) / gameSpeed * delta;
                    imgBgNear.tilePosition.x -= (mainStep / 1.5) / gameSpeed * delta;

                    imgBuilding1.position.x -= mainStep / gameSpeed * delta;
                    imgBuilding2.position.x -= mainStep / gameSpeed * delta;
                    imgBuilding3.position.x -= mainStep / gameSpeed * delta;
                    imgBuilding4.position.x -= mainStep / gameSpeed * delta;
                    imgBuilding5.position.x -= mainStep / gameSpeed * delta;
                    imgBuilding6.position.x -= mainStep / gameSpeed * delta;
                    imgGround.tilePosition.x -= mainStep / gameSpeed * delta;
                    imgStartLine.position.x -= mainStep / gameSpeed * delta;

                    if (imgBuilding1.position.x + imgBuilding1.width < 0) {
                        removeStageItem(imgBuilding1, app)
                    }
                    if (imgBuilding2.position.x + imgBuilding2.width < 0) {
                        removeStageItem(imgBuilding2, app)
                    }
                    if (imgBuilding3.position.x + imgBuilding3.width < 0) {
                        removeStageItem(imgBuilding3, app)
                    }
                    if (imgBuilding4.position.x + imgBuilding4.width < 0) {
                        removeStageItem(imgBuilding4, app)
                    }
                    if (imgBuilding5.position.x + imgBuilding5.width < 0) {
                        removeStageItem(imgBuilding5, app)
                    }
                    if (imgBuilding6.position.x + imgBuilding6.width < 0) {
                        removeStageItem(imgBuilding6, app)
                    }

                }
            });

            function removeStageItem(item, app) {
                app.stage.removeChild(item);
            }
        }

    };

    return (
        <>
            <div id='game-wrapper' ref={gameCanvas} style={{ width: '100%', height: '100%', overflow: 'hidden' }}></div>
        </>
    );
}

export default ScreenOnGame;
