const Footer = () => {
    return (
        <footer>
            <div className='container'>
                Techcombank Marathon HCMC | Developed by VML Vietnam Group
            </div>
        </footer>
    )
}

export default Footer;