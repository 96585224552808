import { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import mainBG from '../assets/img/img_bg_main_2.jpg';
import BadBoy from '../assets/img/boy.png';
import BadGirl from '../assets/img/girl.png';
import iconNext from '../assets/img/icon_arrow_next.svg';
import iconClose from '../assets/img/icon_close.svg';
import { Link, useNavigate } from 'react-router-dom';

import PopupComponent from '../components/PopupComponent';
import LogoWrap from '../components/Logo';

const RegisterPage = () => {
    const [phone, setPhone] = useState(``);
    const [name, setName] = useState(``);
    const [isPhoneState, setIsPhoneState] = useState(false);
    const [isNameState, setIsNameState] = useState(false);
    const [isCharacter, setIsCharacter] = useState(0);
    const [onCloseState, setOnCloseState] = useState(false);
    const [isRegisterDone, setIsRegisterDone] = useState(false);
    const [message, setMessage] = useState(``);
    const navigate = useNavigate();

    const handleRegister = async () => {
        if (phone && name) {
            const vnPhoneRegex = /^(0|\+84)(9\d|1[2|6|8|9]|3[2-9]|5[6|8|9]|7[0|6|7|8|9]|8[1|2|3|4|5|6|8|9]|)([0-9]{7})$/;
            const isValid = vnPhoneRegex.test(phone);
            const isValidName = name.length > 0;

            if (isValid && isValidName) {
                // try {
                //     await fetch(`${process.env.REACT_APP_API_URI}/api/register`, {
                //         method: 'POST',
                //         headers: {
                //             'Content-Type': 'application/x-www-form-urlencoded'
                //         },
                //         body: `phone=${phone}&name=${name}&player=${isCharacter}`
                //     })
                //         .then(res => res.json())
                //         .then(data => {
                //             if (data.done === `1`) {
                //                 localStorage.setItem('Rtoken', data.data.token);
                //                 setIsRegisterDone(true);
                //             } else {
                //                 setIsRegisterDone(false);
                //             }

                //             setPhone(``);
                //             setName(``);
                //             setMessage(data.msg);
                //             setOnCloseState(!onCloseState);
                //         });
                // } catch (e) {
                //     console.log(e);
                // }

            } else {
                console.log('Invalid phone number / name');
                setMessage(`Số điện thoại hoặc tên không hợp lệ!`);
                setOnCloseState(!onCloseState);
            }
        } else {
            setMessage(`Vui lòng nhập đầy đủ thông tin!`);
            setOnCloseState(!onCloseState);
        }

        console.log('handleLogin');
    }

    const handleOnClose = () => {
        setOnCloseState(!onCloseState);
    }

    const inputMouseDown = (type) => {
        switch (type) {
            case `tel`:
                if (phone === ``) setIsPhoneState(true);
                break;
            case `text`:
                if (name === ``) setIsNameState(true);
                break;
            default:
        }
    }

    const inputMouseUp = (type) => {
        switch (type) {
            case `tel`:
                if (phone === ``) setIsPhoneState(false);
                break;
            case `text`:
                if (name === ``) setIsNameState(false);
                break;
            default:
        }
    }

    return (
        <div className='page register' id="registerPage">
            <div className='container'>
                <div className='wrapper'>
                    <img className='main-bg' src={mainBG} alt='Ho Chi Minh City International Marathon - Techcombank' />

                    <div className='el-wrap top'>
                        <LogoWrap />
                    </div>

                    <div className='el-wrap text-center bottom'>
                        <h1>Đăng ký tài khoản</h1>
                        <div className='register-wrap'>
                            <p>Số điện thoại*</p>
                            <div className='btn btn-yellow'>
                                <span>
                                    <strong className={isPhoneState ? `is-flicker` : ``}>{phone === `` ? `Nhập SĐT` : phone}</strong>
                                </span>
                                <input
                                    type='tel'
                                    value={phone}
                                    onMouseDown={e => inputMouseDown(e.target.type)}
                                    onMouseLeave={e => inputMouseUp(e.target.type)}
                                    onChange={e => {
                                        setPhone(e.target.value)
                                        if (e.target.value) setIsPhoneState(false);
                                        else setIsPhoneState(true);
                                    }}></input>
                            </div>

                            <p>Tên nhân vật*</p>

                            <div className='btn btn-yellow'>
                                <span>
                                    <strong className={isNameState ? `is-flicker` : ``}>{name === `` ? `Nhập tên` : name}</strong>
                                </span>
                                <input
                                    maxLength={30}
                                    type='text'
                                    value={name}
                                    onMouseDown={e => inputMouseDown(e.target.type)}
                                    onMouseLeave={e => inputMouseUp(e.target.type)}
                                    onChange={e => {
                                        setName(e.target.value)
                                        if (e.target.value) setIsNameState(false);
                                        else setIsNameState(true);
                                    }}></input>
                            </div>

                            <p>Chọn nhân vật*</p>

                            <div className='character-wrap'>
                                <div
                                    className={`box boy ${isCharacter === 0 && `active`}`}
                                    onClick={() => setIsCharacter(0)}
                                >
                                    <div className='bg-box'>
                                        <img src={BadBoy} alt='Boy' />
                                    </div>
                                </div>
                                <div
                                    className={`box girl ${isCharacter === 1 && `active`}`}
                                    onClick={() => { setIsCharacter(1) }}
                                >
                                    <div className='bg-box'>
                                        <img src={BadGirl} alt='Girl' />
                                    </div>
                                </div>
                            </div>

                            <p className='note'>
                                <small>
                                    *phần thông tin bắt buộc
                                </small>
                            </p>
                        </div>

                        <div className='btn btn-red mt-0 btn-register' onClick={handleRegister}>
                            <span>
                                Hoàn tất
                                <img src={iconNext} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            <PopupComponent onClose={onCloseState} type={`warning`}>
                <div className="box" onClick={handleOnClose}>
                    <div
                        className="content"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <p>{message}</p>
                        {
                            isRegisterDone &&
                            <Link className='btn btn-blue' to='/play'><span>Chơi ngay</span></Link>
                        }
                        <div className="close-btn" onClick={handleOnClose}>
                            <img src={iconClose} />
                        </div>
                    </div>
                </div>
            </PopupComponent>
        </div>
    )
}

export default RegisterPage;