
import { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import mainBG from '../assets/img/img_bg_main.jpg';
import iconClose from '../assets/img/icon_close.svg';
import { Link, useNavigate } from 'react-router-dom';

import PopupComponent from '../components/PopupComponent';
import LogoWrap from '../components/Logo';

const LoginPage = () => {
    const [phone, setPhone] = useState(``);
    const [isPhoneState, setIsPhoneState] = useState(false);
    const [onCloseState, setOnCloseState] = useState(false);
    const [message, setMessage] = useState(``);
    const [isLoginDone, setIsLoginDone] = useState(false);
    const [showRegisterButton, setShowRegisterButton] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async () => {
        if(phone) {
            // const vnPhoneRegex = /^(0|\+84)(9\d|1[2|6|8|9]|3[2-9]|5[6|8|9]|7[0|6|7|8|9]|8[1|2|3|4|5|6|8|9]|)([0-9]{7})$/;
            // const isValid = vnPhoneRegex.test(phone);

            // if(isValid) {
            //     // console.log(phone);
            //     try {
            //         await fetch(`${process.env.REACT_APP_API_URI}/api/login`, {
            //             method: 'POST',
            //             headers: {
            //                 'Content-Type': 'application/x-www-form-urlencoded'
            //             },
            //             body: `phone=${phone}`
            //         })
            //         .then(res => res.json())
            //         .then(data => {
            //             if(data.data) {
            //                 setMessage(data.msg);
            //                 setIsLoginDone(true);
            //                 localStorage.setItem('Rtoken', data.data.token);
            //                 // setTimeout(() => {
            //                 //     navigate('/');
            //                 // }, 100);
            //             } else {
            //                 setMessage(data.msg);
            //                 setShowRegisterButton(true);
            //                 // console.log(data.msg);
            //             }

            //             setPhone(``);
            //             setOnCloseState(!onCloseState);
            //         });
            //     } catch(e) {
            //         console.log(e);
            //     }
                
            // } else {
            //     setMessage(`Số điện thoại không hợp lệ!`);
            //     setOnCloseState(!onCloseState);
            // }
        } else {
            setMessage(`Vui lòng nhập số điện thoại!`);
            setOnCloseState(!onCloseState);
        }
    }

    const inputMouseDown = () => {
        if(phone === ``) {
            setIsPhoneState(true);
        }
    }

    const inputMouseUp = () => {
        if(phone === ``) {
            setIsPhoneState(false);
        }
    }

    const handleOnClose = () => {
        setOnCloseState(!onCloseState);
        setShowRegisterButton(false);
    }

    // useEffect(() => {}, [phone]);
    return (
        <div className='page login' id="loginPage">
            <div className='container'>
                <div className='wrapper'>
                    <img className='main-bg' src={mainBG} alt='Ho Chi Minh City International Marathon - Techcombank' />

                    <div className='el-wrap top'>
                        <LogoWrap />
                        <h1>Bước Chạy <br/> Vượt Trội</h1>
                    </div>
                    

                    <div className='el-wrap gradient-box bottom text-center login-wrap'>
                        <p>Nhập số điện thoại đã đăng ký</p>
                        <div className='btn btn-rose'>
                            <span>
                                <strong className={isPhoneState ? `is-flicker` : ``}>{phone === `` ? `Nhập SĐT` : phone}</strong>
                            </span>
                            <input
                                type={"tel"}
                                value={phone}
                                onMouseDown={inputMouseDown}
                                onMouseLeave={inputMouseUp}
                                onChange={e => {
                                    setPhone(e.target.value);
                                    if(e.target.value) setIsPhoneState(false);
                                    else setIsPhoneState(true);
                                }}></input>    
                        </div>

                        <div className='btn btn-yellow' onClick={handleLogin}>
                            <span>
                                Đăng nhập
                            </span>
                        </div>

                        <p>Bạn chưa có tài khoản</p>

                        <Link className='btn btn-blue' to='/register'>
                            <span>
                                Đăng ký
                            </span>
                        </Link>
                    </div>
                </div>
            </div>

            <Footer />

            <PopupComponent onClose={onCloseState} type={`warning`}>
                <div className="box" onClick={handleOnClose}>
                    <div
                        className="content"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <p>{message}</p>
                        {
                            isLoginDone && 
                            <Link className='btn btn-blue' to='/play'><span>Chơi ngay</span></Link>
                        }

                        {
                            showRegisterButton &&
                            <Link className='btn btn-blue' to='/register'><span>Đăng ký</span></Link>
                        }
                        <div className="close-btn" onClick={handleOnClose}>
                            <img src={iconClose} />
                        </div>    
                    </div>
                </div>
            </PopupComponent>
        </div>
    )
}

export default LoginPage;