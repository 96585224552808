import { useState, useEffect } from "react";
import { Base64 } from "js-base64";

import useElementFullHeight from "../hooks/useElementFullHeight";
import ScreenOnGame from "../components/ScreenOnGame";
import ScreenPreGame from "../components/ScreenPreGame";
import ScreenEndGame from "../components/ScreenEndGame";
import { Link } from "react-router-dom";

import PopupComponent from "../components/PopupComponent";

const GamePlayPage = () => {
  const [gameState, setGameState] = useState(0);
  /*  Game state declare:
        0: Pre enter game screen
        1: On game screen
        2: End game screen (shar facebook, replay)
    */
  const [totalScore, setTotalScore] = useState(0);
  const [userToken, setUserToken] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [shareFbUrl, setShareFbUrl] = useState("");
  const [shareUrl, setShareUrl] = useState("");

  const onEndGame = (totalScore) => {
    setTotalScore(totalScore);
    submitScore(totalScore);
  };

  const onEnterGame = async () => {
    // //find local token
    // const Rtoken = localStorage.getItem("Rtoken");

    // //if there is no token in local storage
    // if (!Rtoken || (Rtoken && !Rtoken.split(".").length)) {
    //   setMessage(
    //     `Vui lòng đăng nhập hoặc đăng ký bằng Số Điện Thoại để tham gia trò chơi.`
    //   );
    //   setShowPopup(true);
    //   return;
    // }

    // //if there is exist token in local storage, check expired or not
    // try {
    // const response = await fetch(`${process.env.REACT_APP_API_URI}/api/verify`, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //         'Authorization': `Bearer ${userToken}`
    //     }
    // });
    // const data = await response.json();
    // if (data.done === "0") {
    //     setMessage(`Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại để tham gia trò chơi.`);
    //     setShowPopup(true);
    // } else {
    //     //verifed
    //     setGameState(1);
    // }
    // } catch (e) {
    //   console.error("Error submitting score:", e);
    // }

    //Dummy data - start
    setGameState(1);
  };

  useEffect(() => {
    //get user info
    // const Rtoken = localStorage.getItem("Rtoken");
    // if (!Rtoken || (Rtoken && !Rtoken.split(".").length)) {
    //   setMessage(
    //     `Vui lòng đăng nhập hoặc đăng ký bằng Số Điện Thoại để tham gia trò chơi.`
    //   );
    //   setShowPopup(true);
    //   return;
    // }

    // setUserToken(Rtoken);
    // const parts = Rtoken.split(".");

    //If token format is wrong
    // if (parts.length !== 3) {
    //   setMessage(
    //     `Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại để tham gia trò chơi.`
    //   );
    //   setShowPopup(true);
    // } else {
    //   //If token format is right
    //   const userData = JSON.parse(Base64.decode(parts[1]));
    //   const user = {
    //     name: userData.name,
    //     player: userData.player,
    //   };
    //   setUserInfo(user);
    // }

    //Dummy data - start
    const userInfo = {
      name: "Phong Nguyen",
      player: "0", //male
    };

    setUserInfo(userInfo);
    //Dummy data - end
  }, []);

  //detect move out browser tab
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        window.location.reload();
      }
    };

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const submitScore = async (score) => {
    // try {
    //   const response = await fetch(
    //     `${process.env.REACT_APP_API_URI}/api/submit`,
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //         Authorization: `Bearer ${userToken}`,
    //       },
    //       body: `score=${score}`,
    //     }
    //   );

    //   if (!response.ok) {
    //     throw new Error(`HTTP error! Status: ${response.status}`);
    //   }

    //   const data = await response.json();

    //   if (data.done === "0") {
    //     setMessage(
    //       `Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại để tham gia trò chơi.`
    //     );
    //     setShowPopup(true);
    //   } else {
    //     setShareFbUrl(
    //       data.done === "1" && data.data && data.data.share
    //         ? data.data.share
    //         : ""
    //     );
    //     setShareUrl(
    //       data.done === "1" && data.data && data.data.link ? data.data.link : ""
    //     );
    //     setGameState(2);
    //   }
    // } catch (e) {
    //   console.error("Error submitting score:", e);
    // }

    //Dummy data - start
    setGameState(2);
    //Dummy data - end
  };

  //handle sharing
  const handleShare = async () => {
    // if (navigator.share) {
    //   const shareData = {
    //     title: "TECHCOMBANK",
    //     text: "Bước chạy vượt trội",
    //     url: shareUrl,
    //   };
    //   try {
    //     await navigator.share(shareData);
    //     console.log("MDN shared successfully");
    //   } catch (err) {
    //     console.log(`Error: ${err}`);
    //   }
    // } else {
    //   window.open(shareFbUrl, "_blank");
    //   console.log("navigator.share is not supported");
    // }
  };

  //container height
  const elementHeight = useElementFullHeight(".element-to-exclude");

  return (
    <div className="gameplay-page general-page" id="gameplaypage">
      <div className="container" style={{ minHeight: `${elementHeight}px` }}>
        {gameState === 0 ? (
          <ScreenPreGame userInfo={userInfo} onEnterGame={onEnterGame} />
        ) : (
          ""
        )}
        {gameState === 1 ? (
          <ScreenOnGame userInfo={userInfo} onEndGame={onEndGame} />
        ) : (
          ""
        )}
        {gameState === 2 ? (
          <ScreenEndGame
            userInfo={userInfo}
            handleShare={handleShare}
            scoreValue={totalScore}
            onRestartGame={() => setGameState(0)}
          />
        ) : (
          ""
        )}
      </div>

      <PopupComponent onClose={showPopup} type={`warning`}>
        <div className="box">
          <div className="content" onClick={(e) => e.stopPropagation()}>
            <p>{message}</p>
            <Link className="btn btn-blue" to="/login">
              <span>Đăng nhập</span>
            </Link>
          </div>
        </div>
      </PopupComponent>
    </div>
  );
};

export default GamePlayPage;
